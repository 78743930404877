import '../styles/index.scss';
import React from 'react';
import SEO from '../components/seo';
import '../styles/pages/p_not-available.sass';

const NotAvailable = () => {
  return (
    <div className="not-available-page page">
      <SEO title='Not Available' />

      <div>
        <img src="/icons/logo_big.svg" alt="logo" />
        <h1>САЙТ НАХОДИТСЯ В РАЗРАБОТКЕ</h1>
        <h3>Всю информацию о жилом комплексе можно узнать по адресу</h3>
        <div className="phone">
          <img src="/icons/phone-icon.svg" alt="phone icon" />
          <span>073 562 55 82</span>
        </div>
      </div>
    </div>
  )
}

export default NotAvailable;